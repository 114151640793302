/* Section styling */

  .Client{
    display: flex;
    justify-content: center;
  }
  /* Partner title styling */
  h2 {
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  
  /* Flex container for the partner logos */
  .partner {
    display: flex;
    justify-content: center; /* Centers the ul horizontally */
  }
  
  /* Flexbox styling for partner-box (unordered list) */
  .partner-box {
    display: flex;
    flex-wrap: wrap; /* Allows the logos to wrap to the next line when screen space is limited */
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: center;
    gap: 20px; /* Adds space between logos */
  }
  
  /* Flexbox item styling for each list item */
  .partner-box li {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(4 32 105);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 300px; /* Set a fixed width for the logos */
    height: 200px; /* Set a fixed height for the logos */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Ensures all images fit properly within their container */
  .partner-box li img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Keeps the aspect ratio of the logos */
  }
  