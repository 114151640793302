.form-column.col-lg-6.col-md-12.col-sm-12 {
  margin-top: -60px;
}

h2 {
  font-size: 30px;
  font-weight: bold;
  color: #000;
}

p {
  font-size: 18px;
  padding-left: 12px;
}



.contact-info-block .content-box .text-content {
  position: relative;
  display: block;
  padding: 42px 35px;
  background: #ffffff;
  box-shadow: 3px 3px 8px #888;
}

.map-canvas {
  max-width: 100%; /* Adjusted to max-width for responsiveness */
  width: 100%; /* Added for responsiveness */
  box-shadow: 5px 5px 10px #888;
}


.default-form contact-form{
  width: 100%;
  padding: 10%;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 10px; 
  box-sizing: border-box;
}

.default-form.contact-form {
  border: 2px; 
  padding: 20px; 
  background-color: #ffffff; 
  border-radius: 15px; 
  max-width: 700px; /* Adjusted to max-width for responsiveness */
  width: 100%; /* Added for responsiveness */
  box-shadow: 3px 3px 8px #888;
}

.default-form.contact-form input[type="text"],
.default-form.contact-form input[type="date"],
.default-form.contact-form input[type="email"],
/* .default-form .form-group select, */
.default-form.contact-form textarea {
  width: calc(100% - 20px); /* Adjusted width calculation to account for padding */
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 10px; 
  box-sizing: border-box;
}
.default-form .form-group select.custom-select-box{
  width: calc(100% - 20px); /* Adjusted width calculation to account for padding */
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 10px; 
  box-sizing: border-box;
}