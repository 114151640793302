.kp-avatar{
    width: 39px;
    height: 39px;
    border-radius: 37%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    background-image: url('../../../../public/favicon3.png');
    background-size: cover;
    color: #fff;
    margin-right: 5px;
}
.botform{
  border: 2px solid #ccc; /* Add the desired color for the outline */
  padding: 20px; /* Add some padding to the form */
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 2px 2px 6px #888888;
}
.image{
    color: blue;
    text-shadow: 10px;
}
.bot{
    background-color: rgba(255, 251, 254, 0.829);
    border:2px solid #e0e0e0;
    box-shadow: 0px 0px 1px #888888;
}

.btn-style-one .btn-title {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 700;
    padding: 8px 20px;
    background: #f74883;
    border-radius: 0px;
    z-index: 1;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.img-component {
    border: 2px solid #ccc; /* Add the desired color for the border */
    padding: 10px; /* Add padding to the component */
    text-align: center; /* Center-align the content */
    box-shadow: 2px 2px 6px #888888;
    border-radius: 10px;
  }
  
  .img-container {
    margin-bottom: 10px;
  }
  
  .image {
    font-size: 13px; /* Adjust the font size as needed */
    margin-bottom: 5px;
    color: rgb(43, 42, 42);
    text-decoration: underline;
  }
  
  .image-preview {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    border: 1px solid #ddd; /* Add a border to the image */
    border-radius: 5px; /* Optional: Add border radius for rounded corners */
  }
  
  .linkedin-link {
    font-size: 12px;
    display: block;
    margin-top: 10px;
    text-decoration: none;
    color: #007BFF; /* Add the desired color for the link */
  }
  
  .link{
    font-size: 14px;
  }