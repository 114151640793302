@font-face {
  font-family: 'Flaticon';
  src:  url('../fonts/Flaticon.eot');
  src:  url('../fonts/Flaticon.eot#iefix') format('embedded-opentype'),
    url('../fonts/Flaticon.ttf') format('truetype'),
    url('../fonts/Flaticon.woff2') format('woff2'),
    url('../fonts/Flaticon.woff') format('woff'),
    url('../fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: 'Flaticon' !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-cell-phone:before { content: "\f100"; }
.flaticon-straight-quotes:before { content: "\f101"; }
.flaticon-blocks-with-angled-cuts:before { content: "\f102"; }
.flaticon-graph-line-screen:before { content: "\f103"; }
.flaticon-smartphone-call:before { content: "\f104"; }
.flaticon-plus-cross:before { content: "\f105"; }
.flaticon-electric-light-bulb:before { content: "\f106"; }
.flaticon-e-mail-envelope:before { content: "\f107"; }
.flaticon-cross-shaped-target:before { content: "\f108"; }
.flaticon-targeting-cross:before { content: "\f109"; }
.flaticon-tool:before { content: "\f10a"; }
.flaticon-angle-pointing-to-left:before { content: "\f10b"; }
.flaticon-angle-arrow-pointing-to-right:before { content: "\f10c"; }
.flaticon-media-play-symbol:before { content: "\f10d"; }
.flaticon-chart-of-columns:before { content: "\f10e"; }
.flaticon-quotation-mark:before { content: "\f10f"; }
.flaticon-wellness-logo:before { content: "\f110"; }
.flaticon-placeholder-on-map-paper-in-perspective:before { content: "\f111"; }
.flaticon-arrow:before { content: "\f112"; }
.flaticon-down-arrow:before { content: "\f113"; }
.flaticon-up-arrow-angle:before { content: "\f114"; }
.flaticon-right-arrow-angle:before { content: "\f115"; }
.flaticon-double-right-arrows-angles:before { content: "\f116"; }
.flaticon-circle:before { content: "\f117"; }
.flaticon-line:before { content: "\f118"; }
.flaticon-sign:before { content: "\f119"; }
.flaticon-menu-of-three-lines:before { content: "\f11a"; }
.flaticon-delete:before { content: "\f11b"; }
.flaticon-check-symbol:before { content: "\f11c"; }
.flaticon-round:before { content: "\f11d"; }
.flaticon-delete-cross:before { content: "\f11e"; }
.flaticon-location:before { content: "\f11f"; }
.flaticon-clipboard-with-a-list:before { content: "\f120"; }
.flaticon-shelter:before { content: "\f121"; }
.flaticon-technology:before { content: "\f122"; }
.flaticon-fax-with-phone:before { content: "\f123"; }
.flaticon-boat-anchor:before { content: "\f124"; }
.flaticon-interface:before { content: "\f125"; }
.flaticon-photo:before { content: "\f126"; }
.flaticon-social-media:before { content: "\f127"; }
.flaticon-discuss-issue:before { content: "\f128"; }
.flaticon-quote:before { content: "\f129"; }
.flaticon-support:before { content: "\f12a"; }
.flaticon-paint-palette-and-brush:before { content: "\f12b"; }
.flaticon-arrow-1:before { content: "\f12c"; }
.flaticon-shape:before { content: "\f12d"; }
.flaticon-play-button:before { content: "\f12e"; }
.flaticon-heart:before { content: "\f12f"; }
.flaticon-arrows:before { content: "\f130"; }
.flaticon-play-button-1:before { content: "\f131"; }
.flaticon-home:before { content: "\f132"; }
.flaticon-letter-x:before { content: "\f133"; }
.flaticon-download-arrow:before { content: "\f134"; }
.flaticon-cross:before { content: "\f135"; }
.flaticon-line-1:before { content: "\f136"; }
.flaticon-right-arrow:before { content: "\f137"; }
.flaticon-arrows-1:before { content: "\f138"; }
.flaticon-smartphone:before { content: "\f139"; }
.flaticon-nature:before { content: "\f13a"; }
.flaticon-email:before { content: "\f13b"; }
.flaticon-light-bulb:before { content: "\f13c"; }
.flaticon-letter:before { content: "\f13d"; }
.flaticon-magnifying-glass:before { content: "\f13e"; }
.flaticon-multimedia:before { content: "\f13f"; }
.flaticon-arrows-2:before { content: "\f140"; }
.flaticon-arrows-3:before { content: "\f141"; }
.flaticon-arrows-4:before { content: "\f142"; }
.flaticon-arrows-5:before { content: "\f143"; }
.flaticon-arrows-6:before { content: "\f144"; }
.flaticon-checked:before { content: "\f145"; }
.flaticon-line-2:before { content: "\f146"; }
.flaticon-truck:before { content: "\f147"; }
.flaticon-coin:before { content: "\f148"; }
.flaticon-headphones:before { content: "\f149"; }
.flaticon-shopping-bag:before { content: "\f14a"; }
.flaticon-money:before { content: "\f14b"; }
.flaticon-money-1:before { content: "\f14c"; }
.flaticon-telephone:before { content: "\f14d"; }
.flaticon-settings:before { content: "\f14e"; }
.flaticon-garbage:before { content: "\f14f"; }
.flaticon-placeholder:before { content: "\f150"; }
.flaticon-heart-1:before { content: "\f151"; }
.flaticon-settings-1:before { content: "\f152"; }
.flaticon-magnifying-glass-1:before { content: "\f153"; }
.flaticon-link:before { content: "\f154"; }
.flaticon-pie-chart:before { content: "\f155"; }
.flaticon-printer:before { content: "\f156"; }
.flaticon-next:before { content: "\f157"; }
.flaticon-back:before { content: "\f158"; }
.flaticon-share:before { content: "\f159"; }
.flaticon-house:before { content: "\f15a"; }
.flaticon-cancel:before { content: "\f15b"; }
.flaticon-phone-call:before { content: "\f15c"; }
.flaticon-shopping-bag-1:before { content: "\f15d"; }
.flaticon-envelope:before { content: "\f15e"; }
.flaticon-computer:before { content: "\f15f"; }
.flaticon-next-1:before { content: "\f160"; }
.flaticon-diagonal-arrow:before { content: "\f161"; }
.flaticon-menu:before { content: "\f162"; }
.flaticon-menu-1:before { content: "\f163"; }
.flaticon-comment:before { content: "\f164"; }
.flaticon-comment-1:before { content: "\f165"; }
.flaticon-chat:before { content: "\f166"; }
.flaticon-arrows-7:before { content: "\f167"; }
.flaticon-calendar:before { content: "\f168"; }
.flaticon-picture:before { content: "\f169"; }
.flaticon-play-button-2:before { content: "\f16a"; }
.flaticon-play-button-3:before { content: "\f16b"; }
.flaticon-success:before { content: "\f16c"; }
.flaticon-add:before { content: "\f16d"; }
.flaticon-placeholder-1:before { content: "\f16e"; }
.flaticon-placeholder-2:before { content: "\f16f"; }
.flaticon-home-1:before { content: "\f170"; }
.flaticon-placeholder-3:before { content: "\f171"; }
.flaticon-user:before { content: "\f172"; }
.flaticon-play-button-4:before { content: "\f173"; }
.flaticon-play-button-5:before { content: "\f174"; }
.flaticon-menu-2:before { content: "\f175"; }
.flaticon-right-quote:before { content: "\f176"; }
.flaticon-idea:before { content: "\f177"; }
.flaticon-interface-1:before { content: "\f178"; }
.flaticon-up-arrow:before { content: "\f179"; }
.flaticon-arrows-8:before { content: "\f17a"; }
.flaticon-arrows-9:before { content: "\f17b"; }
.flaticon-arrows-10:before { content: "\f17c"; }
.flaticon-close:before { content: "\f17d"; }
.flaticon-user-1:before { content: "\f17e"; }
.flaticon-bar-chart:before { content: "\f17f"; }
.flaticon-speech-bubble:before { content: "\f180"; }
.flaticon-placeholder-4:before { content: "\f181"; }
.flaticon-technology-1:before { content: "\f182"; }
.flaticon-technology-2:before { content: "\f183"; }
.flaticon-desktop:before { content: "\f184"; }
.flaticon-link-1:before { content: "\f185"; }
.flaticon-menu-3:before { content: "\f186"; }
.flaticon-trophy:before { content: "\f187"; }
.flaticon-placeholder-5:before { content: "\f188"; }
.flaticon-smartphone-1:before { content: "\f189"; }
.flaticon-smartphone-2:before { content: "\f18a"; }
.flaticon-layers:before { content: "\f18b"; }
.flaticon-monitor:before { content: "\f18c"; }
.flaticon-home-2:before { content: "\f18d"; }
.flaticon-interface-2:before { content: "\f18e"; }
.flaticon-bar-chart-1:before { content: "\f18f"; }
.flaticon-message:before { content: "\f190"; }
.flaticon-settings-2:before { content: "\f191"; }
.flaticon-quality:before { content: "\f192"; }
.flaticon-telephone-1:before { content: "\f193"; }
.flaticon-agenda:before { content: "\f194"; }
.flaticon-image:before { content: "\f195"; }
.flaticon-maps-and-flags:before { content: "\f196"; }
.flaticon-delete-1:before { content: "\f197"; }
.flaticon-cancel-1:before { content: "\f198"; }
.flaticon-logout:before { content: "\f199"; }
.flaticon-line-chart:before { content: "\f19a"; }
.flaticon-email-1:before { content: "\f19b"; }
.flaticon-multimedia-option:before { content: "\f19c"; }
.flaticon-like:before { content: "\f19d"; }
.flaticon-bar-chart-2:before { content: "\f19e"; }
.flaticon-menu-4:before { content: "\f19f"; }
.flaticon-calendar-1:before { content: "\f1a0"; }
.flaticon-hand-shake:before { content: "\f1a1"; }
.flaticon-briefcase:before { content: "\f1a2"; }
.flaticon-agenda-1:before { content: "\f1a3"; }
.flaticon-technology-3:before { content: "\f1a4"; }
.flaticon-arrows-11:before { content: "\f1a5"; }
.flaticon-menu-5:before { content: "\f1a6"; }
.flaticon-menu-6:before { content: "\f1a7"; }
.flaticon-photo-1:before { content: "\f1a8"; }
.flaticon-settings-3:before { content: "\f1a9"; }
.flaticon-placeholder-6:before { content: "\f1aa"; }
.flaticon-photo-camera:before { content: "\f1ab"; }
.flaticon-quote-1:before { content: "\f1ac"; }
.flaticon-calendar-2:before { content: "\f1ad"; }
.flaticon-mail:before { content: "\f1ae"; }
.flaticon-worker:before { content: "\f1af"; }
.flaticon-chat-1:before { content: "\f1b0"; }
.flaticon-leaf:before { content: "\f1b1"; }
.flaticon-desktop-1:before { content: "\f1b2"; }
.flaticon-desktop-2:before { content: "\f1b3"; }
.flaticon-clipboard:before { content: "\f1b4"; }
.flaticon-money-2:before { content: "\f1b5"; }
.flaticon-work:before { content: "\f1b6"; }
.flaticon-envelope-1:before { content: "\f1b7"; }
.flaticon-information:before { content: "\f1b8"; }
.flaticon-chat-2:before { content: "\f1b9"; }
.flaticon-mail-1:before { content: "\f1ba"; }
.flaticon-resume:before { content: "\f1bb"; }
.flaticon-conversation:before { content: "\f1bc"; }
.flaticon-smartphone-3:before { content: "\f1bd"; }
.flaticon-school:before { content: "\f1be"; }
.flaticon-call:before { content: "\f1bf"; }
.flaticon-heart-2:before { content: "\f1c0"; }
.flaticon-speech-bubble-1:before { content: "\f1c1"; }
.flaticon-nuclear:before { content: "\f1c2"; }
.flaticon-magnifying-glass-2:before { content: "\f1c3"; }
.flaticon-chat-3:before { content: "\f1c4"; }
.flaticon-photo-camera-1:before { content: "\f1c5"; }
.flaticon-magnifying-glass-3:before { content: "\f1c6"; }
.flaticon-shield:before { content: "\f1c7"; }
.flaticon-mail-2:before { content: "\f1c8"; }
.flaticon-suitcase:before { content: "\f1c9"; }
.flaticon-menu-7:before { content: "\f1ca"; }
.flaticon-menu-8:before { content: "\f1cb"; }
.flaticon-team:before { content: "\f1cc"; }
.flaticon-security:before { content: "\f1cd"; }
.flaticon-dollar-symbol:before { content: "\f1ce"; }
.flaticon-power:before { content: "\f1cf"; }
.flaticon-consult:before { content: "\f1d0"; }
.flaticon-code:before { content: "\f1d1"; }
.flaticon-report:before { content: "\f1d2"; }
.flaticon-bio:before { content: "\f1d3"; }
.flaticon-consulting:before { content: "\f1d4"; }
.flaticon-help:before { content: "\f1d5"; }
.flaticon-save:before { content: "\f1d6"; }
.flaticon-answer:before { content: "\f1d7"; }
.flaticon-avatar:before { content: "\f1d8"; }
.flaticon-oil:before { content: "\f1d9"; }
.flaticon-login:before { content: "\f1da"; }
.flaticon-logout-1:before { content: "\f1db"; }
.flaticon-conversation-1:before { content: "\f1dc"; }
.flaticon-energy:before { content: "\f1dd"; }
.flaticon-location-pin:before { content: "\f1de"; }
.flaticon-menu-9:before { content: "\f1df"; }
.flaticon-location-1:before { content: "\f1e0"; }