@font-face {
	font-family: 'themify';
	src:url('../fonts/themify9f24.eot?-fvbane');
	src:url('../fonts/themifyd41d.eot?#iefix-fvbane') format('embedded-opentype'),
		url('../fonts/themify9f24.woff?-fvbane') format('woff'),
		url('../fonts/themify9f24.ttf?-fvbane') format('truetype'),
		url('../fonts/themify9f24.svg?-fvbane#themify') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="themify-"], [class*=" themify-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.themify-wand:before {
	content: "\e600";
}
.themify-volume:before {
	content: "\e601";
}
.themify-user:before {
	content: "\e602";
}
.themify-unlock:before {
	content: "\e603";
}
.themify-unlink:before {
	content: "\e604";
}
.themify-trash:before {
	content: "\e605";
}
.themify-thought:before {
	content: "\e606";
}
.themify-target:before {
	content: "\e607";
}
.themify-tag:before {
	content: "\e608";
}
.themify-tablet:before {
	content: "\e609";
}
.themify-star:before {
	content: "\e60a";
}
.themify-spray:before {
	content: "\e60b";
}
.themify-signal:before {
	content: "\e60c";
}
.themify-shopping-cart:before {
	content: "\e60d";
}
.themify-shopping-cart-full:before {
	content: "\e60e";
}
.themify-settings:before {
	content: "\e60f";
}
.themify-search:before {
	content: "\e610";
}
.themify-zoom-in:before {
	content: "\e611";
}
.themify-zoom-out:before {
	content: "\e612";
}
.themify-cut:before {
	content: "\e613";
}
.themify-ruler:before {
	content: "\e614";
}
.themify-ruler-pencil:before {
	content: "\e615";
}
.themify-ruler-alt:before {
	content: "\e616";
}
.themify-bookmark:before {
	content: "\e617";
}
.themify-bookmark-alt:before {
	content: "\e618";
}
.themify-reload:before {
	content: "\e619";
}
.themify-plus:before {
	content: "\e61a";
}
.themify-pin:before {
	content: "\e61b";
}
.themify-pencil:before {
	content: "\e61c";
}
.themify-pencil-alt:before {
	content: "\e61d";
}
.themify-paint-roller:before {
	content: "\e61e";
}
.themify-paint-bucket:before {
	content: "\e61f";
}
.themify-na:before {
	content: "\e620";
}
.themify-mobile:before {
	content: "\e621";
}
.themify-minus:before {
	content: "\e622";
}
.themify-medall:before {
	content: "\e623";
}
.themify-medall-alt:before {
	content: "\e624";
}
.themify-marker:before {
	content: "\e625";
}
.themify-marker-alt:before {
	content: "\e626";
}
.themify-arrow-up:before {
	content: "\e627";
}
.themify-arrow-right:before {
	content: "\e628";
}
.themify-arrow-left:before {
	content: "\e629";
}
.themify-arrow-down:before {
	content: "\e62a";
}
.themify-lock:before {
	content: "\e62b";
}
.themify-location-arrow:before {
	content: "\e62c";
}
.themify-link:before {
	content: "\e62d";
}
.themify-layout:before {
	content: "\e62e";
}
.themify-layers:before {
	content: "\e62f";
}
.themify-layers-alt:before {
	content: "\e630";
}
.themify-key:before {
	content: "\e631";
}
.themify-import:before {
	content: "\e632";
}
.themify-image:before {
	content: "\e633";
}
.themify-heart:before {
	content: "\e634";
}
.themify-heart-broken:before {
	content: "\e635";
}
.themify-hand-stop:before {
	content: "\e636";
}
.themify-hand-open:before {
	content: "\e637";
}
.themify-hand-drag:before {
	content: "\e638";
}
.themify-folder:before {
	content: "\e639";
}
.themify-flag:before {
	content: "\e63a";
}
.themify-flag-alt:before {
	content: "\e63b";
}
.themify-flag-alt-2:before {
	content: "\e63c";
}
.themify-eye:before {
	content: "\e63d";
}
.themify-export:before {
	content: "\e63e";
}
.themify-exchange-vertical:before {
	content: "\e63f";
}
.themify-desktop:before {
	content: "\e640";
}
.themify-cup:before {
	content: "\e641";
}
.themify-crown:before {
	content: "\e642";
}
.themify-comments:before {
	content: "\e643";
}
.themify-comment:before {
	content: "\e644";
}
.themify-comment-alt:before {
	content: "\e645";
}
.themify-close:before {
	content: "\e646";
}
.themify-clip:before {
	content: "\e647";
}
.themify-angle-up:before {
	content: "\e648";
}
.themify-angle-right:before {
	content: "\e649";
}
.themify-angle-left:before {
	content: "\e64a";
}
.themify-angle-down:before {
	content: "\e64b";
}
.themify-check:before {
	content: "\e64c";
}
.themify-check-box:before {
	content: "\e64d";
}
.themify-camera:before {
	content: "\e64e";
}
.themify-announcement:before {
	content: "\e64f";
}
.themify-brush:before {
	content: "\e650";
}
.themify-briefcase:before {
	content: "\e651";
}
.themify-bolt:before {
	content: "\e652";
}
.themify-bolt-alt:before {
	content: "\e653";
}
.themify-blackboard:before {
	content: "\e654";
}
.themify-bag:before {
	content: "\e655";
}
.themify-move:before {
	content: "\e656";
}
.themify-arrows-vertical:before {
	content: "\e657";
}
.themify-arrows-horizontal:before {
	content: "\e658";
}
.themify-fullscreen:before {
	content: "\e659";
}
.themify-arrow-top-right:before {
	content: "\e65a";
}
.themify-arrow-top-left:before {
	content: "\e65b";
}
.themify-arrow-circle-up:before {
	content: "\e65c";
}
.themify-arrow-circle-right:before {
	content: "\e65d";
}
.themify-arrow-circle-left:before {
	content: "\e65e";
}
.themify-arrow-circle-down:before {
	content: "\e65f";
}
.themify-angle-double-up:before {
	content: "\e660";
}
.themify-angle-double-right:before {
	content: "\e661";
}
.themify-angle-double-left:before {
	content: "\e662";
}
.themify-angle-double-down:before {
	content: "\e663";
}
.themify-zip:before {
	content: "\e664";
}
.themify-world:before {
	content: "\e665";
}
.themify-wheelchair:before {
	content: "\e666";
}
.themify-view-list:before {
	content: "\e667";
}
.themify-view-list-alt:before {
	content: "\e668";
}
.themify-view-grid:before {
	content: "\e669";
}
.themify-uppercase:before {
	content: "\e66a";
}
.themify-upload:before {
	content: "\e66b";
}
.themify-underline:before {
	content: "\e66c";
}
.themify-truck:before {
	content: "\e66d";
}
.themify-timer:before {
	content: "\e66e";
}
.themify-ticket:before {
	content: "\e66f";
}
.themify-thumb-up:before {
	content: "\e670";
}
.themify-thumb-down:before {
	content: "\e671";
}
.themify-text:before {
	content: "\e672";
}
.themify-stats-up:before {
	content: "\e673";
}
.themify-stats-down:before {
	content: "\e674";
}
.themify-split-v:before {
	content: "\e675";
}
.themify-split-h:before {
	content: "\e676";
}
.themify-smallcap:before {
	content: "\e677";
}
.themify-shine:before {
	content: "\e678";
}
.themify-shift-right:before {
	content: "\e679";
}
.themify-shift-left:before {
	content: "\e67a";
}
.themify-shield:before {
	content: "\e67b";
}
.themify-notepad:before {
	content: "\e67c";
}
.themify-server:before {
	content: "\e67d";
}
.themify-quote-right:before {
	content: "\e67e";
}
.themify-quote-left:before {
	content: "\e67f";
}
.themify-pulse:before {
	content: "\e680";
}
.themify-printer:before {
	content: "\e681";
}
.themify-power-off:before {
	content: "\e682";
}
.themify-plug:before {
	content: "\e683";
}
.themify-pie-chart:before {
	content: "\e684";
}
.themify-paragraph:before {
	content: "\e685";
}
.themify-panel:before {
	content: "\e686";
}
.themify-package:before {
	content: "\e687";
}
.themify-music:before {
	content: "\e688";
}
.themify-music-alt:before {
	content: "\e689";
}
.themify-mouse:before {
	content: "\e68a";
}
.themify-mouse-alt:before {
	content: "\e68b";
}
.themify-money:before {
	content: "\e68c";
}
.themify-microphone:before {
	content: "\e68d";
}
.themify-menu:before {
	content: "\e68e";
}
.themify-menu-alt:before {
	content: "\e68f";
}
.themify-map:before {
	content: "\e690";
}
.themify-map-alt:before {
	content: "\e691";
}
.themify-loop:before {
	content: "\e692";
}
.themify-location-pin:before {
	content: "\e693";
}
.themify-list:before {
	content: "\e694";
}
.themify-light-bulb:before {
	content: "\e695";
}
.themify-Italic:before {
	content: "\e696";
}
.themify-info:before {
	content: "\e697";
}
.themify-infinite:before {
	content: "\e698";
}
.themify-id-badge:before {
	content: "\e699";
}
.themify-hummer:before {
	content: "\e69a";
}
.themify-home:before {
	content: "\e69b";
}
.themify-help:before {
	content: "\e69c";
}
.themify-headphone:before {
	content: "\e69d";
}
.themify-harddrives:before {
	content: "\e69e";
}
.themify-harddrive:before {
	content: "\e69f";
}
.themify-gift:before {
	content: "\e6a0";
}
.themify-game:before {
	content: "\e6a1";
}
.themify-filter:before {
	content: "\e6a2";
}
.themify-files:before {
	content: "\e6a3";
}
.themify-file:before {
	content: "\e6a4";
}
.themify-eraser:before {
	content: "\e6a5";
}
.themify-envelope:before {
	content: "\e6a6";
}
.themify-download:before {
	content: "\e6a7";
}
.themify-direction:before {
	content: "\e6a8";
}
.themify-direction-alt:before {
	content: "\e6a9";
}
.themify-dashboard:before {
	content: "\e6aa";
}
.themify-control-stop:before {
	content: "\e6ab";
}
.themify-control-shuffle:before {
	content: "\e6ac";
}
.themify-control-play:before {
	content: "\e6ad";
}
.themify-control-pause:before {
	content: "\e6ae";
}
.themify-control-forward:before {
	content: "\e6af";
}
.themify-control-backward:before {
	content: "\e6b0";
}
.themify-cloud:before {
	content: "\e6b1";
}
.themify-cloud-up:before {
	content: "\e6b2";
}
.themify-cloud-down:before {
	content: "\e6b3";
}
.themify-clipboard:before {
	content: "\e6b4";
}
.themify-car:before {
	content: "\e6b5";
}
.themify-calendar:before {
	content: "\e6b6";
}
.themify-book:before {
	content: "\e6b7";
}
.themify-bell:before {
	content: "\e6b8";
}
.themify-basketball:before {
	content: "\e6b9";
}
.themify-bar-chart:before {
	content: "\e6ba";
}
.themify-bar-chart-alt:before {
	content: "\e6bb";
}
.themify-back-right:before {
	content: "\e6bc";
}
.themify-back-left:before {
	content: "\e6bd";
}
.themify-arrows-corner:before {
	content: "\e6be";
}
.themify-archive:before {
	content: "\e6bf";
}
.themify-anchor:before {
	content: "\e6c0";
}
.themify-align-right:before {
	content: "\e6c1";
}
.themify-align-left:before {
	content: "\e6c2";
}
.themify-align-justify:before {
	content: "\e6c3";
}
.themify-align-center:before {
	content: "\e6c4";
}
.themify-alert:before {
	content: "\e6c5";
}
.themify-alarm-clock:before {
	content: "\e6c6";
}
.themify-agenda:before {
	content: "\e6c7";
}
.themify-write:before {
	content: "\e6c8";
}
.themify-window:before {
	content: "\e6c9";
}
.themify-widgetized:before {
	content: "\e6ca";
}
.themify-widget:before {
	content: "\e6cb";
}
.themify-widget-alt:before {
	content: "\e6cc";
}
.themify-wallet:before {
	content: "\e6cd";
}
.themify-video-clapper:before {
	content: "\e6ce";
}
.themify-video-camera:before {
	content: "\e6cf";
}
.themify-vector:before {
	content: "\e6d0";
}
.themify-themify-logo:before {
	content: "\e6d1";
}
.themify-themify-favicon:before {
	content: "\e6d2";
}
.themify-themify-favicon-alt:before {
	content: "\e6d3";
}
.themify-support:before {
	content: "\e6d4";
}
.themify-stamp:before {
	content: "\e6d5";
}
.themify-split-v-alt:before {
	content: "\e6d6";
}
.themify-slice:before {
	content: "\e6d7";
}
.themify-shortcode:before {
	content: "\e6d8";
}
.themify-shift-right-alt:before {
	content: "\e6d9";
}
.themify-shift-left-alt:before {
	content: "\e6da";
}
.themify-ruler-alt-2:before {
	content: "\e6db";
}
.themify-receipt:before {
	content: "\e6dc";
}
.themify-pin2:before {
	content: "\e6dd";
}
.themify-pin-alt:before {
	content: "\e6de";
}
.themify-pencil-alt2:before {
	content: "\e6df";
}
.themify-palette:before {
	content: "\e6e0";
}
.themify-more:before {
	content: "\e6e1";
}
.themify-more-alt:before {
	content: "\e6e2";
}
.themify-microphone-alt:before {
	content: "\e6e3";
}
.themify-magnet:before {
	content: "\e6e4";
}
.themify-line-double:before {
	content: "\e6e5";
}
.themify-line-dotted:before {
	content: "\e6e6";
}
.themify-line-dashed:before {
	content: "\e6e7";
}
.themify-layout-width-full:before {
	content: "\e6e8";
}
.themify-layout-width-default:before {
	content: "\e6e9";
}
.themify-layout-width-default-alt:before {
	content: "\e6ea";
}
.themify-layout-tab:before {
	content: "\e6eb";
}
.themify-layout-tab-window:before {
	content: "\e6ec";
}
.themify-layout-tab-v:before {
	content: "\e6ed";
}
.themify-layout-tab-min:before {
	content: "\e6ee";
}
.themify-layout-slider:before {
	content: "\e6ef";
}
.themify-layout-slider-alt:before {
	content: "\e6f0";
}
.themify-layout-sidebar-right:before {
	content: "\e6f1";
}
.themify-layout-sidebar-none:before {
	content: "\e6f2";
}
.themify-layout-sidebar-left:before {
	content: "\e6f3";
}
.themify-layout-placeholder:before {
	content: "\e6f4";
}
.themify-layout-menu:before {
	content: "\e6f5";
}
.themify-layout-menu-v:before {
	content: "\e6f6";
}
.themify-layout-menu-separated:before {
	content: "\e6f7";
}
.themify-layout-menu-full:before {
	content: "\e6f8";
}
.themify-layout-media-right-alt:before {
	content: "\e6f9";
}
.themify-layout-media-right:before {
	content: "\e6fa";
}
.themify-layout-media-overlay:before {
	content: "\e6fb";
}
.themify-layout-media-overlay-alt:before {
	content: "\e6fc";
}
.themify-layout-media-overlay-alt-2:before {
	content: "\e6fd";
}
.themify-layout-media-left-alt:before {
	content: "\e6fe";
}
.themify-layout-media-left:before {
	content: "\e6ff";
}
.themify-layout-media-center-alt:before {
	content: "\e700";
}
.themify-layout-media-center:before {
	content: "\e701";
}
.themify-layout-list-thumb:before {
	content: "\e702";
}
.themify-layout-list-thumb-alt:before {
	content: "\e703";
}
.themify-layout-list-post:before {
	content: "\e704";
}
.themify-layout-list-large-image:before {
	content: "\e705";
}
.themify-layout-line-solid:before {
	content: "\e706";
}
.themify-layout-grid4:before {
	content: "\e707";
}
.themify-layout-grid3:before {
	content: "\e708";
}
.themify-layout-grid2:before {
	content: "\e709";
}
.themify-layout-grid2-thumb:before {
	content: "\e70a";
}
.themify-layout-cta-right:before {
	content: "\e70b";
}
.themify-layout-cta-left:before {
	content: "\e70c";
}
.themify-layout-cta-center:before {
	content: "\e70d";
}
.themify-layout-cta-btn-right:before {
	content: "\e70e";
}
.themify-layout-cta-btn-left:before {
	content: "\e70f";
}
.themify-layout-column4:before {
	content: "\e710";
}
.themify-layout-column3:before {
	content: "\e711";
}
.themify-layout-column2:before {
	content: "\e712";
}
.themify-layout-accordion-separated:before {
	content: "\e713";
}
.themify-layout-accordion-merged:before {
	content: "\e714";
}
.themify-layout-accordion-list:before {
	content: "\e715";
}
.themify-ink-pen:before {
	content: "\e716";
}
.themify-info-alt:before {
	content: "\e717";
}
.themify-help-alt:before {
	content: "\e718";
}
.themify-headphone-alt:before {
	content: "\e719";
}
.themify-hand-point-up:before {
	content: "\e71a";
}
.themify-hand-point-right:before {
	content: "\e71b";
}
.themify-hand-point-left:before {
	content: "\e71c";
}
.themify-hand-point-down:before {
	content: "\e71d";
}
.themify-gallery:before {
	content: "\e71e";
}
.themify-face-smile:before {
	content: "\e71f";
}
.themify-face-sad:before {
	content: "\e720";
}
.themify-credit-card:before {
	content: "\e721";
}
.themify-control-skip-forward:before {
	content: "\e722";
}
.themify-control-skip-backward:before {
	content: "\e723";
}
.themify-control-record:before {
	content: "\e724";
}
.themify-control-eject:before {
	content: "\e725";
}
.themify-comments-smiley:before {
	content: "\e726";
}
.themify-brush-alt:before {
	content: "\e727";
}
.themify-youtube:before {
	content: "\e728";
}
.themify-vimeo:before {
	content: "\e729";
}
.themify-twitter:before {
	content: "\e72a";
}
.themify-time:before {
	content: "\e72b";
}
.themify-tumblr:before {
	content: "\e72c";
}
.themify-skype:before {
	content: "\e72d";
}
.themify-share:before {
	content: "\e72e";
}
.themify-share-alt:before {
	content: "\e72f";
}
.themify-rocket:before {
	content: "\e730";
}
.themify-pinterest:before {
	content: "\e731";
}
.themify-new-window:before {
	content: "\e732";
}
.themify-microsoft:before {
	content: "\e733";
}
.themify-list-ol:before {
	content: "\e734";
}
.themify-linkedin:before {
	content: "\e735";
}
.themify-layout-sidebar-2:before {
	content: "\e736";
}
.themify-layout-grid4-alt:before {
	content: "\e737";
}
.themify-layout-grid3-alt:before {
	content: "\e738";
}
.themify-layout-grid2-alt:before {
	content: "\e739";
}
.themify-layout-column4-alt:before {
	content: "\e73a";
}
.themify-layout-column3-alt:before {
	content: "\e73b";
}
.themify-layout-column2-alt:before {
	content: "\e73c";
}
.themify-instagram:before {
	content: "\e73d";
}
.themify-google:before {
	content: "\e73e";
}
.themify-github:before {
	content: "\e73f";
}
.themify-flickr:before {
	content: "\e740";
}
.themify-facebook:before {
	content: "\e741";
}
.themify-dropbox:before {
	content: "\e742";
}
.themify-dribbble:before {
	content: "\e743";
}
.themify-apple:before {
	content: "\e744";
}
.themify-android:before {
	content: "\e745";
}
.themify-save:before {
	content: "\e746";
}
.themify-save-alt:before {
	content: "\e747";
}
.themify-yahoo:before {
	content: "\e748";
}
.themify-wordpress:before {
	content: "\e749";
}
.themify-vimeo-alt:before {
	content: "\e74a";
}
.themify-twitter-alt:before {
	content: "\e74b";
}
.themify-tumblr-alt:before {
	content: "\e74c";
}
.themify-trello:before {
	content: "\e74d";
}
.themify-stack-overflow:before {
	content: "\e74e";
}
.themify-soundcloud:before {
	content: "\e74f";
}
.themify-sharethis:before {
	content: "\e750";
}
.themify-sharethis-alt:before {
	content: "\e751";
}
.themify-reddit:before {
	content: "\e752";
}
.themify-pinterest-alt:before {
	content: "\e753";
}
.themify-microsoft-alt:before {
	content: "\e754";
}
.themify-linux:before {
	content: "\e755";
}
.themify-jsfiddle:before {
	content: "\e756";
}
.themify-joomla:before {
	content: "\e757";
}
.themify-html5:before {
	content: "\e758";
}
.themify-flickr-alt:before {
	content: "\e759";
}
.themify-email:before {
	content: "\e75a";
}
.themify-drupal:before {
	content: "\e75b";
}
.themify-dropbox-alt:before {
	content: "\e75c";
}
.themify-css3:before {
	content: "\e75d";
}
.themify-rss:before {
	content: "\e75e";
}
.themify-rss-alt:before {
	content: "\e75f";
}
