
.navigation li{
display:block;
margin: 2px;
}


 .main-menu .navigation .active a {
    color: #f74883;
  
}


.main-menu .navigation .active a  {
    color: #f74883;
    
  
}



.logo{
  width:285px;
  padding-top: 3px;
}
.Column {

    width: 30%;
    padding: 1%;
  margin-left: 3%;
    height: 250px;
  }
  
  .Column a {
    line-height: 24px;
    font-weight: 700;
    font-size: 89%;
    color: #4a3d8f;
    padding: 5%;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid rgba(0,0,0,0.10);
  }
  a:hover{color: #f74883;}
 
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
