


.accordion-button:not(.collapsed) {
    color: #ffffff;

   
    background-color: #29008a;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.acc-btn:link, .acc-btn:visited, .acc-btn:hover, .acc-btn:active ,.accordion-button:link, .accordion-button:visited, .accordion-button:hover, .accordion-button:active  {
  background-color: #5500C9 !important;
    color:#FFF !important;
    text-decoration: none !important;
     border: hidden !important;
       border-color: #FFF !important;
    box-shadow: 1px !important;
}

.acc-btn:focus,.accordion-button:focus {
  z-index: 3;
  border-color: #FFF !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem #FFF !important;
}
.accordion-button .acc-btn
{
  background-color: #FFF;
  font-size:15px;
  margin-right: 2rem;
}
.accordion-item{
  color: black;
}







.acc-btn:not(.collapsed) {
    color: #ffffff;

   
    background-color: #29008a;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button
{
  background-color: #FFF;
  width: 100%;
  padding:20px 32px;
  border: 2px solid #bbc3bb; 
}
h2{
    font-size: 16px;
    
    width:100%;
   height:100%;
}
.flaticon-down-arrow:hover{
  color: white;
}
